export const papers = [
    {
        id: 1,
        title: 'Wondering on and with Purpose',
        year: '2022',
        journal: 'Oxford Studies in Philsophy of Mind',
        url: 'https://drive.google.com/file/d/1xDWq1AseStHLAEIlUM-mxjULZbscaQua/view?usp=sharing'
    },
    {
        id: 2,
        title: 'Reasoning beyond belief acquisition',
        year: '2021',
        journal: 'Noûs',
        abstract: "I argue that we can reason not only to new beliefs but to basically any change in attitude we can think of, including the abandonment of belief (contra John Broome), the acquisition of non-belief attitudes like relief and admiration, and the elimination of the same. To argue for this position, which I call generalism, I defend a sufficient condition on reasoning, roughly that we can reason to any change in attitude that is expressed by the conclusion of an argument we can be convinced by. I then produce examples of such arguments, and argue that they are indeed arguments. To produce examples of the elimination of non-doxastic attitudes, I develop the idea of a state of attitudinal constraint acceptance, and show how it is useful for solving this problem, and useful in other parts of philosophy as well.",
        url: 'https://drive.google.com/file/d/12wmPs5Wh3tHdcyS_PbqNAU59yotKt7Ec/view?usp=sharing'
    },
    {
        id: 3,
        title: 'Changes in attitude',
        year: '2021',
        journal: 'Philosophical Perspectives',
        url: 'https://drive.google.com/file/d/1887SKBeTQTz4GNtw8dz94FN-N7-gcwlc/view?usp=sharing'
    },
    {
        id: 4,
        title: 'The Attitudes We Can Have',
        year: '2020',
        journal: 'Philosophical Review',
        abstract: "In this article, I'm going to explain why there are many propositions that have good reason to believe are true but that we cannot rationally believe, and why this kind of phenomenon only occurs with doxastic attitudes like belief and not non-doxastic attitudes like admiration. To do that, I will present a general theory of attitude formation as a kind of choice.",
        url: 'https://drive.google.com/file/d/1SL9PUoO9zXBljWmrHOpOrWhVu2YkQ6Tm/view?usp=sharing'
    },
    {
        id: 2,
        title: 'Policy Externalism',
        year: '2019',
        journal: 'Philosophical and Phenomenological Research',
        abstract: "I develop and argue for a kind of externalism about certain kinds of non-doxastic attitudes that I call policy externalism. Policy externalism about a given type of attitude is the view that all the reasonable policies for having attitudes of that type will not involve the agent’s beliefs that some relevant conditions obtain. My defense primarily involves attitudes like hatred, regret, and admiration, and has two parts: a direct deductive argument and an indirect linguistic argument, an inference to the best explanation of some strange ways we use certain conditionals. The main thought throughout is that attitudes we reason with, like belief, are very different from attitudes we don’t reason with, in a way that constrains the former but not the latter. Finally, I investigate some consequences of policy externalism, including that it secures the possibility of genuine conditional apologies.",
        url: 'https://drive.google.com/file/d/1CKjfvMrf58VFY5d5cYl4zjHwxBJxO9xb/view?usp=sharing'
    },
    {
        id: 2,
        title: 'A Rightness-Based Theory of Communicative Propriety',
        year: '2019',
        journal: 'Australasian Journal of Philosophy',
        abstract: "We express and communicate many attitudes beyond belief, such as amusement, joy, admiration, hatred, and desire. I consider whether there are any general norms that would cover all of these cases. The most obvious generalisation of the most popular norms for assertion, fittingness-based theories, fail in part because it is sometimes an intrinsic good to have certain kinds of mental states (amusement, say). I develop an alternative, rightness-based, approach, according to which it is appropriate to communicate a mental state to an interlocutor when it is right to make the interlocutor have that mental state because of the speech act. This view arises naturally from conversational participants’ common interests, and it helps to make sense of linguistic phenomena like expressives.",
        url: 'https://drive.google.com/file/d/1LvzxFQ0lXjwdjyGuLqHgQPC5N6dqYMhc/view?usp=sharing'
    },
    {
        id: 7,
        title: 'When propriety is improper',
        year: '2017',
        coauthor: 'Kevin Blackwell',
        journal: 'Philosophical Studies',
        abstract: "We argue that philosophers ought to distinguish epistemic decision theory and epistemology, in just the way ordinary decision theory is distinguished from ethics. Once one does this, the internalist arguments that motivate much of epistemic decision theory make sense, given specific interpretations of the formalism (for example, that epistemic utility functions be at least as psychologically real as ordinary utility functions are for decision theory). Making this distinction also causes trouble for the principle called Propriety, which says, roughly, that the only acceptable epistemic utility functions make probabilistically coherent credence functions immodest (expect themselves to be least inaccurate). We cast doubt on this requirement, but then argue that epistemic decision theorists should never have wanted such a strong principle in any case.",
        url: 'https://drive.google.com/file/d/1rMpx6WkFDekLgdzyDdJINI6prFPHlwfA/view?usp=sharing'
    },
    {
        id: 8,
        title: 'Neo-Stoicism and What It Can Do',
        year: '2016',
        journal: 'Ergo',
        url: 'https://quod.lib.umich.edu/e/ergo/12405314.0003.021?view=text;rgn=main'
    },
]

export const syllabi = [

]

export const pastSyllabi = [
    {
        id: 1,
        title: 'Philosophy and Film',
        semester: 'Fall 2021',
        url: 'https://drive.google.com/file/d/1n_MzS6pAefVMnR0DDLd2JeKLFJm6BrXP/view?usp=sharing'
    },
    {
        id: 2,
        title: 'Questions and Belief',
        semester: 'Fall 2021',
        url: 'https://drive.google.com/file/d/1YJlGUFqBdudbzcj0RS4ZtdQn9Bjf23jd/view?usp=sharing'
    },
    {
        id: 3,
        title: 'Theory of Knowledge',
        semester: 'Fall 2020',
        url: 'https://drive.google.com/file/d/19Zw4u3Ui100GbkUOVcWo4AC5D8yXd7Gx/view?usp=sharing'
    },
    {
        id: 4,
        title: 'Interpretation and Meaning',
        semester: 'Fall 2020',
        url: 'https://drive.google.com/file/d/1oUk2fP-yzMrI4MqBd92ft3q8w9SQfS9-/view?usp=sharing'
    },
]